import type { RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import type { LDClient } from 'launchdarkly-js-client-sdk'

import { authGuard } from '@/auth'
import { BYOC_BASE_ON_CLOUD_ENVIRONMENT_FLAG } from '@/auth/ld/ld'

export const multipleLevelPages = [
  // these pages are multiple level pages: cluster or tenant or namespace level
  'FunctionsPage',
  'ConnectorsPage',
  'PulsarConnectorsPage',
  'KafkaConnectorsPage',
  'PulsarConnectorCreatePage',
  'KafkaConnectorCreatePage'
]

export const orgLevelPages = ['DashboardPage', 'InstancesPage']
export const instanceLevelPages = ['ClustersPage']
export const clusterLevelPages = [
  'ClusterPage',
  'ClusterDashboard',
  'ClusterOverview',
  'SecretsPage',
  'MetricsApiPage',
  'TenantsPage',
  'PulsarClientsPage',
  'KafkaClientsPage',
  'MqttClientsPage',
  'ClusterConfiguration',
  ...multipleLevelPages
]
export const tenantLevelPages = [
  'TenantDashboard',
  'NamespacesPage',
  'TenantConfiguration',
  ...multipleLevelPages
]
export const namespaceLevelPages = [
  'TopicMetrics',
  'TopicOverview',
  'TopicSchema',
  'TopicConfiguration',
  'TopicMessages',
  'TopicsPage',
  'NamespaceConfiguration',
  'NamespaceOverview',
  'NamespaceDashboard',
  'PfSqlListPage',
  'PfSqlPage',
  'PfSqlOverview',
  'PfSqlLogs',
  'PfSqlExceptions',
  'PfSqlDeployPage',
  'FunctionDashboard',
  'FunctionOverview',
  'FunctionLogs',
  'FunctionExceptions',
  'FunctionPage',
  'PulsarConnectorPage',
  'PulsarConnectorDashboard',
  'PulsarConnectorLogs',
  'PulsarConnectorExceptions',
  'PulsarConnectorEditPage',
  'KafkaConnectorPage',
  'KafkaConnectorLogs',
  'KafkaConnectorDashboard',
  'KafkaConnectorConfigurationPage',
  'KafkaConnectorExceptions',
  'KafkaConnectorEditPage',
  ...multipleLevelPages
]
export const topicLevelPages = [
  'TopicMetrics',
  'TopicOverview',
  'TopicSchema',
  'TopicMessages',
  'TopicStorage',
  'TopicPolicies',
  'TopicStats',
  'TopicConfiguration'
]

export const usageLevelPage = ['OrganizationUsagePage', 'OrganizationProfilePage']
export const accountAccessLevelPage = ['UsersPage', 'ServiceAccountsPage', 'ServiceAccountPage']
export const infrastructureLevelPage = [
  'CloudEnvironmentManagePage',
  'CloudEnvironmentsPage',
  'CloudConnectionsPage'
]

const {
  canAlterSelfOrganization,
  canDescribeBilling,
  canDescribeCluster,
  canDescribeUser,
  canDescribeInstanceList,
  canDescribeClusterList,
  canDescribePulsarMetrics,
  canDescribeTenantList,
  canDescribeTenant,
  canDescribeNamespaceList,
  canDescribeNamespace,
  canDescribeTopicList,
  canDescribeTopic,
  canDescribeSA,
  canDescribeApiKey,
  canDescribeSecret,
  canDescribeConnectorList,
  canDescribeConnector,
  canCreateConnector,
  canUpdateConnector,
  canDescribeFunctionList,
  canUpdateCluster,
  canDescribeFunction,
  canDescribeCloudEnvironment,
  canDescribeCloudConnection,
  canCreateCloudEnvironment,
  canCreateInstance,
  canUpdateInstance,
  canCreateCluster,
  canProduceMessages,
  canConsumeMessages,
  canDescribeKsnConnectorList,
  canDescribeKsnConnector,
  canCreateKsnConnector,
  canUpdateKsnConnector,
  canDescribePfSqlList,
  canDescribePfSql,
  canCreatePfSql
} = rbacManager()

const openRoutes = <Array<RouteRecordRaw>>[
  // TODO remove me?
  {
    path: '/logs',
    name: 'LogsPage',
    component: () => import('@/components/nb-extension/NbLogs.vue')
  },
  {
    path: '/check-email',
    name: 'CheckEmailPage',
    component: () => import('@/views/login/CheckEmailPage.vue')
  },
  // TODO remove me?
  {
    path: '/email-verified',
    name: 'EmailVerifiedPage',
    component: () => import('@/views/login/EmailVerifiedPage.vue')
  },
  {
    path: '/callback/failed',
    name: 'CallbackFailedPage',
    component: () => import('@/views/login/CallbackFailedPage.vue')
  },
  {
    path: '/callback',
    name: 'CallbackPage',
    component: () => import('@/views/login/CallbackPage.vue')
  },
  // TODO remove me?
  {
    path: '/error',
    name: 'errorPage',
    component: () => import('@/views/error/ErrorPage.vue')
  },
  {
    path: '/404',
    name: 'NotFoundPage',
    component: () => import('@/views/error/NotFoundPage.vue')
  }
].map(r => {
  return {
    ...r,
    meta: { requiresAuth: false }
  }
})

export const authRoutes = <Array<RouteRecordRaw>>[
  {
    path: '/signup',
    name: 'SignupFlowPage',
    component: () => import('@/views/login/SignupFlowPage.vue')
  },
  {
    path: '/suger/error',
    name: 'SugerErrorPage',
    component: () => import('@/views/login/SugerErrorPage.vue'),
    meta: {
      requiresAuth: true,
      authFn: canDescribeBilling
    }
  },
  {
    path: '/payment',
    name: 'PaymentPage',
    component: () => import('@/views/billing/PaymentPage.vue'),
    meta: {
      requiresAuth: true,
      authFn: canDescribeBilling
    }
  },
  {
    path: '/organizations',
    name: 'OrganizationsPage',
    meta: {
      requiresAuth: true
      // Organizations page is allowed for all users that are authenticated
      // it requires no extra permissions
    },
    component: () => import('@/views/organization/OrganizationsPage.vue')
  },
  {
    path: '/',
    component: () => import('@/layouts/MainLayout.vue'),
    children: [
      {
        path: '/',
        name: 'DashboardPage',
        component: () => import('@/views/dashboard/DashboardPage.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/organization-profile',
        name: 'OrganizationProfilePage',
        meta: {
          requiresAuth: true,
          authFn: canAlterSelfOrganization,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org
          }
        },
        component: () => import('@/views/organization/OrganizationProfilePage.vue')
      },
      {
        path: '/organization-usage',
        name: 'OrganizationUsagePage',
        meta: {
          requiresAuth: true,
          authFn: canDescribeBilling,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org
          }
        },
        component: () => import('@/views/organization/OrganizationUsagePage.vue')
      },
      {
        path: '/users',
        name: 'UsersPage',
        component: () => import('@/views/user/UsersPage.vue'),
        meta: {
          requiresAuth: true,
          authFn: canDescribeUser,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org
          }
        }
      },
      {
        path: '/instances',
        name: 'InstancesPage',
        component: () => import('@/views/instance/InstancesPage.vue'),
        meta: {
          requiresAuth: true,
          authFn: canDescribeInstanceList,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org
          }
        }
      },
      {
        path: '/clients/pulsar',
        name: 'PulsarClientsPage',
        component: () => import('@/views/client/PulsarClientsPage.vue'),
        meta: {
          requiresAuth: true,
          authFn: canDescribeCluster,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org && !!to.query.instance && !!to.query.cluster
          }
        }
      },
      {
        path: '/clients/kafka',
        name: 'KafkaClientsPage',
        component: () => import('@/views/client/KafkaClientsPage.vue'),
        meta: {
          requiresAuth: true,
          authFn: canDescribeCluster,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org && !!to.query.instance && !!to.query.cluster
          }
        }
      },
      {
        path: '/clients/mqtt',
        name: 'MqttClientsPage',
        component: () => import('@/views/client/MqttClientsPage.vue'),
        meta: {
          requiresAuth: true,
          authFn: canDescribeCluster,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org && !!to.query.instance && !!to.query.cluster
          }
        }
      },
      {
        path: '/metrics-api',
        name: 'MetricsApiPage',
        component: () => import('@/views/client/MetricsApiPage.vue'),
        meta: {
          requiresAuth: true,
          authFn: canDescribeCluster,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org && !!to.query.instance && !!to.query.cluster
          }
        }
      },
      {
        path: '/clusters',
        name: 'ClustersPage',
        component: () => import('@/views/cluster/ClustersPage.vue'),
        meta: {
          requiresAuth: true,
          authFn: canDescribeClusterList,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org && !!to.query.instance
          }
        }
      },
      {
        path: '/cluster',
        name: 'ClusterPage',
        component: () => import('@/views/cluster/ClusterPage.vue'),
        redirect: { name: 'ClusterDashboard' },
        meta: {
          requiresAuth: true,
          authFn: canDescribeCluster,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org && !!to.query.instance && !!to.query.cluster
          }
        },
        children: [
          {
            path: 'dashboard',
            name: 'ClusterDashboard',
            component: () => import('@/views/cluster/ClusterDashboard.vue'),
            meta: {
              requiresAuth: true,
              authFn: canDescribePulsarMetrics,
              checkFn: ({ to }: { to: RouteLocationNormalized }) => {
                return !!to.query.org && !!to.query.instance && !!to.query.cluster
              },
              replaceFn: () => 'ClusterOverview'
            }
          },
          {
            path: 'overview',
            name: 'ClusterOverview',
            component: () => import('@/views/cluster/ClusterOverview.vue'),
            meta: {
              requiresAuth: true,
              authFn: canDescribeCluster,
              checkFn: ({ to }: { to: RouteLocationNormalized }) => {
                return !!to.query.org && !!to.query.instance && !!to.query.cluster
              }
            }
          }
        ]
      },
      {
        path: '/cluster/configuration',
        name: 'ClusterConfiguration',
        component: () => import('@/views/cluster/ClusterConfiguration.vue'),
        meta: {
          requiresAuth: true,
          authFn: canDescribeCluster,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org && !!to.query.instance && !!to.query.cluster
          }
        }
      },
      {
        path: '/tenants',
        name: 'TenantsPage',
        component: () => import('@/views/tenant/TenantsPage.vue'),
        meta: {
          requiresAuth: true,
          authFn: canDescribeTenantList,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org && !!to.query.instance && !!to.query.cluster
          }
        }
      },
      {
        path: '/tenant/dashboard',
        name: 'TenantDashboard',
        component: () => import('@/views/tenant/TenantDashboard.vue'),
        meta: {
          requiresAuth: true,
          authFn: canDescribePulsarMetrics,
          replaceFn: () => 'TenantConfiguration',
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org && !!to.query.instance && !!to.query.cluster && !!to.query.tenant
          }
        }
      },
      {
        path: 'tenant/configuration',
        name: 'TenantConfiguration',
        component: () => import('@/views/tenant/TenantConfiguration.vue'),
        meta: {
          requiresAuth: true,
          authFn: canDescribeTenant,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org && !!to.query.instance && !!to.query.cluster && !!to.query.tenant
          }
        }
      },
      {
        path: 'namespaces',
        name: 'NamespacesPage',
        component: () => import('@/views/tenant/NamespaceList.vue'),
        meta: {
          requiresAuth: true,
          authFn: canDescribeNamespaceList,
          replaceFn: () => 'NamespaceOverview',
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org && !!to.query.instance && !!to.query.cluster && !!to.query.tenant
          }
        }
      },
      {
        path: 'namespace/dashboard',
        name: 'NamespaceDashboard',
        component: () => import('@/views/tenant/namespace/dashboard/NamespaceDashboard.vue'),
        props: true,
        meta: {
          requiresAuth: true,
          authFn: canDescribePulsarMetrics,
          replaceFn: () => 'NamespaceConfiguration',
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return (
              !!to.query.org &&
              !!to.query.instance &&
              !!to.query.cluster &&
              !!to.query.tenant &&
              !!to.query.namespace
            )
          }
        }
      },
      {
        path: 'namespace/overview',
        name: 'NamespaceOverview',
        props: true,
        component: () => import('@/views/tenant/namespace/overview/NamespaceOverview.vue'),
        meta: {
          requiresAuth: true,
          authFn: canDescribeNamespace,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return (
              !!to.query.org &&
              !!to.query.instance &&
              !!to.query.cluster &&
              !!to.query.tenant &&
              !!to.query.namespace
            )
          }
        }
      },
      {
        path: 'namespace/configuration',
        name: 'NamespaceConfiguration',
        props: true,
        component: () =>
          import('@/views/tenant/namespace/configuration/NamespaceConfiguration.vue'),
        meta: {
          requiresAuth: true,
          authFn: canDescribeNamespace,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return (
              !!to.query.org &&
              !!to.query.instance &&
              !!to.query.cluster &&
              !!to.query.tenant &&
              !!to.query.namespace
            )
          }
        }
      },
      {
        path: '/topics',
        component: () => import('@/views/topic/TopicsPage.vue'),
        name: 'TopicsPage',
        meta: {
          requiresAuth: true,
          authFn: canDescribeTopicList,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return (
              !!to.query.org &&
              !!to.query.instance &&
              !!to.query.cluster &&
              !!to.query.tenant &&
              !!to.query.namespace
            )
          }
        }
      },
      {
        path: '/topic',
        component: () => import('@/views/topic/TopicPage.vue'),
        name: 'TopicPage',
        redirect: { name: 'TopicMetrics' },
        children: [
          {
            path: 'dashboard',
            name: 'TopicMetrics',
            component: () => import('@/views/topic/overview/TopicMetrics.vue'),
            meta: {
              requiresAuth: true,
              authFn: canDescribePulsarMetrics,
              checkFn: ({ to }: { to: RouteLocationNormalized }) => {
                return (
                  !!to.query.org &&
                  !!to.query.instance &&
                  !!to.query.cluster &&
                  !!to.query.tenant &&
                  !!to.query.namespace &&
                  !!to.query.topic
                )
              },
              replaceFn: () => 'TopicOverview'
            }
          },
          {
            path: 'overview',
            name: 'TopicOverview',
            component: () => import('@/views/topic/overview/TopicOverview.vue'),
            meta: {
              requiresAuth: true,
              authFn: canDescribeTopic,
              checkFn: ({ to }: { to: RouteLocationNormalized }) => {
                return (
                  !!to.query.org &&
                  !!to.query.instance &&
                  !!to.query.cluster &&
                  !!to.query.tenant &&
                  !!to.query.namespace &&
                  !!to.query.topic
                )
              }
            }
          },
          {
            path: 'schema',
            name: 'TopicSchema',
            component: () => import('@/views/topic/schema/TopicSchema.vue'),
            meta: {
              requiresAuth: true,
              authFn: canDescribeTopic,
              checkFn: ({ to }: { to: RouteLocationNormalized }) => {
                return (
                  !!to.query.org &&
                  !!to.query.instance &&
                  !!to.query.cluster &&
                  !!to.query.tenant &&
                  !!to.query.namespace &&
                  !!to.query.topic
                )
              }
            }
          },
          {
            path: 'messages',
            name: 'TopicMessages',
            component: () => import('@/views/topic/messages/TopicMessages.vue'),
            meta: {
              requiresAuth: true,
              authFn: ({ to }: { to: RouteLocationNormalized }) =>
                canProduceMessages({ to }) && canConsumeMessages({ to }),
              checkFn: ({ to }: { to: RouteLocationNormalized }) => {
                return (
                  !!to.query.org &&
                  !!to.query.instance &&
                  !!to.query.cluster &&
                  !!to.query.tenant &&
                  !!to.query.namespace &&
                  !!to.query.topic
                )
              },
              replaceFn: () => 'TopicOverview'
            }
          },
          {
            path: 'configuration',
            name: 'TopicConfiguration',
            component: () => import('@/views/topic/configuration/TopicConfiguration.vue'),
            meta: {
              requiresAuth: true,
              authFn: canDescribeTopic,
              checkFn: ({ to }: { to: RouteLocationNormalized }) => {
                return (
                  !!to.query.org &&
                  !!to.query.instance &&
                  !!to.query.cluster &&
                  !!to.query.tenant &&
                  !!to.query.namespace &&
                  !!to.query.topic
                )
              }
            }
          },
          {
            path: 'stats',
            name: 'TopicStats',
            component: () => import('@/views/topic/stats/TopicStats.vue'),
            meta: {
              requiresAuth: true,
              authFn: canDescribeTopic,
              checkFn: ({ to }: { to: RouteLocationNormalized }) => {
                return (
                  !!to.query.org &&
                  !!to.query.instance &&
                  !!to.query.cluster &&
                  !!to.query.tenant &&
                  !!to.query.namespace &&
                  !!to.query.topic
                )
              }
            }
          }
        ],
        meta: {
          requiresAuth: true,
          authFn: canDescribeTopicList,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return (
              !!to.query.org &&
              !!to.query.instance &&
              !!to.query.cluster &&
              !!to.query.tenant &&
              !!to.query.namespace &&
              !!to.query.topic
            )
          }
        }
      },
      {
        path: '/service-accounts',
        component: () => import('@/views/service-account/ServiceAccountsPage.vue'),
        name: 'ServiceAccountsPage',
        meta: {
          requiresAuth: true,
          authFn: canDescribeSA,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org
          }
        }
      },
      {
        path: '/service-account/:id',
        name: 'ServiceAccountPage',
        component: () => import('@/views/service-account/ServiceAccountPage.vue'),
        meta: {
          requiresAuth: true,
          authFn: canDescribeApiKey,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org && !!to.query.name
          }
        }
      },
      {
        path: '/secrets',
        component: () => import('@/views/secrets/SecretsPage.vue'),
        name: 'SecretsPage',
        meta: {
          requiresAuth: true,
          authFn: canDescribeSecret,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org
          }
        }
      },
      {
        path: '/connector',
        name: 'ConnectorsPage',
        component: () => import('@/views/connector/ConnectorWrapper.vue'),
        redirect: { name: 'PulsarConnectorsPage', params: { type: 'sink' } },
        children: [
          {
            path: 'pulsar/:type',
            name: 'PulsarConnectorsPage',
            props: true,
            component: () => import('@/views/connector/PulsarConnectorsPage.vue'),
            meta: {
              requiresAuth: true,
              authFn: canDescribeConnectorList,
              checkFn: ({ to }: { to: RouteLocationNormalized }) => {
                return !!to.query.org && !!to.query.instance && !!to.query.cluster
              }
            }
          },
          {
            path: 'kafka/:type',
            name: 'KafkaConnectorsPage',
            props: true,
            component: () => import('@/views/connector/KafkaConnectorsPage.vue'),
            meta: {
              requiresAuth: true,
              authFn: canDescribeKsnConnectorList,
              checkFn: ({ to }: { to: RouteLocationNormalized }) => {
                return !!to.query.org && !!to.query.instance && !!to.query.cluster
              }
            }
          }
        ],
        meta: {
          requiresAuth: true,
          authFn: ({ to }: { to: RouteLocationNormalized }) =>
            canDescribeConnectorList({ to }) || canDescribeKsnConnectorList({ to }),
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org && !!to.query.instance && !!to.query.cluster
          }
        }
      },
      {
        path: '/connector/kafka/:type/:name',
        name: 'KafkaConnectorPage',
        component: () => import('@/views/connector/kafka/KafkaConnectorPage.vue'),
        meta: {
          requiresAuth: true,
          authFn: canDescribeKsnConnector,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org && !!to.query.instance && !!to.query.cluster
          }
        },
        props: true,
        redirect: { name: 'KafkaConnectorDashboard' },
        children: [
          {
            path: 'dashboard',
            name: 'KafkaConnectorDashboard',
            component: () => import('@/views/connector/kafka/KafkaConnectorDashboard.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              authFn: canDescribePulsarMetrics,
              checkFn: ({ to }: { to: RouteLocationNormalized }) => {
                return !!to.query.org && !!to.query.instance && !!to.query.cluster
              }
            }
          },
          {
            path: 'logs',
            name: 'KafkaConnectorLogs',
            component: () => import('@/views/connector/kafka/KafkaConnectorLogs.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              authFn: canDescribeKsnConnector,
              checkFn: ({ to }: { to: RouteLocationNormalized }) => {
                return !!to.query.org && !!to.query.instance && !!to.query.cluster
              }
            }
          },
          {
            path: 'configuration',
            name: 'KafkaConnectorConfigurationPage',
            component: () => import('@/views/connector/kafka/KafkaConnectorConfigurationPage.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              authFn: canDescribeKsnConnector,
              checkFn: ({ to }: { to: RouteLocationNormalized }) => {
                return !!to.query.org && !!to.query.instance && !!to.query.cluster
              }
            }
          }
        ]
      },
      {
        path: '/connector/pulsar/:type/:name',
        name: 'PulsarConnectorPage',
        component: () => import('@/views/connector/pulsar/PulsarConnectorPage.vue'),
        redirect: { name: 'PulsarConnectorDashboard' },
        props: true,
        children: [
          {
            path: 'dashboard',
            name: 'PulsarConnectorDashboard',
            component: () => import('@/views/connector/pulsar/PulsarConnectorDashboard.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              authFn: canDescribePulsarMetrics,
              checkFn: ({ to }: { to: RouteLocationNormalized }) => {
                return (
                  !!to.query.org &&
                  !!to.query.instance &&
                  !!to.query.cluster &&
                  !!to.query.tenant &&
                  !!to.query.namespace
                )
              },
              replaceFn: () => 'PulsarConnectorLogs'
            }
          },
          {
            path: 'logs',
            name: 'PulsarConnectorLogs',
            component: () => import('@/views/connector/pulsar/PulsarConnectorLogs.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              authFn: canDescribeConnector,
              checkFn: ({ to }: { to: RouteLocationNormalized }) => {
                return (
                  !!to.query.org &&
                  !!to.query.instance &&
                  !!to.query.cluster &&
                  !!to.query.tenant &&
                  !!to.query.namespace
                )
              }
            }
          },
          {
            path: 'exceptions',
            name: 'PulsarConnectorExceptions',
            component: () => import('@/views/connector/pulsar/PulsarConnectorExceptions.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              authFn: canDescribeConnector,
              checkFn: ({ to }: { to: RouteLocationNormalized }) => {
                return (
                  !!to.query.org &&
                  !!to.query.instance &&
                  !!to.query.cluster &&
                  !!to.query.tenant &&
                  !!to.query.namespace
                )
              }
            }
          }
        ],
        meta: {
          requiresAuth: true,
          authFn: canDescribeConnector,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return (
              !!to.query.org &&
              !!to.query.instance &&
              !!to.query.cluster &&
              !!to.query.tenant &&
              !!to.query.namespace
            )
          }
        }
      },
      {
        path: '/connector/kafka/:type/create',
        name: 'KafkaConnectorCreatePage',
        props: true,
        component: () => import('@/views/connector/kafka/KafkaConnectorEditPage.vue'),
        meta: {
          requiresAuth: true,
          authFn: canCreateKsnConnector,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org && !!to.query.instance && !!to.query.cluster
          }
        }
      },
      {
        path: '/connector/kafka/:type/:name/edit',
        name: 'KafkaConnectorEditPage',
        component: () => import('@/views/connector/kafka/KafkaConnectorEditPage.vue'),
        meta: {
          requiresAuth: true,
          authFn: canUpdateKsnConnector,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org && !!to.query.instance && !!to.query.cluster
          }
        },
        props: true
      },
      {
        path: '/connector/pulsar/:type/create',
        name: 'PulsarConnectorCreatePage',
        props: true,
        component: () => import('@/views/connector/pulsar/PulsarConnectorEditPage.vue'),
        meta: {
          requiresAuth: true,
          authFn: canCreateConnector,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org && !!to.query.instance && !!to.query.cluster
          }
        }
      },
      {
        path: '/connector/pulsar/:type/:name/edit',
        component: () => import('@/views/connector/pulsar/PulsarConnectorEditPage.vue'),
        name: 'PulsarConnectorEditPage',
        props: true,
        meta: {
          requiresAuth: true,
          authFn: canUpdateConnector,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return (
              !!to.query.org &&
              !!to.query.instance &&
              !!to.query.cluster &&
              !!to.query.tenant &&
              !!to.query.namespace
            )
          }
        }
      },
      {
        path: '/functions',
        name: 'FunctionsPage',
        component: () => import('@/views/function/FunctionsPage.vue'),
        meta: {
          requiresAuth: true,
          authFn: canDescribeFunctionList,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org && !!to.query.instance && !!to.query.cluster
          }
        }
      },
      {
        path: '/pfsqls',
        name: 'PfSqlListPage',
        component: () => import('@/views/pfsql/PfSqlListPage.vue'),
        meta: {
          requiresAuth: true,
          // authFn: canDescribePfSqlList,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org && !!to.query.instance && !!to.query.cluster
          }
        }
      },
      {
        path: '/pfsql/deploy',
        name: 'PfSqlDeployPage',
        component: () => import('@/views/pfsql/PfSqlDeployPage.vue'),
        meta: {
          requiresAuth: true,
          authFn: canCreatePfSql,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org && !!to.query.instance && !!to.query.cluster
          }
        }
      },
      {
        path: '/pfsql',
        name: 'PfSqlPage',
        component: () => import('@/views/pfsql/PfSqlPage.vue'),
        redirect: { name: 'PfSqlOverview' },
        children: [
          {
            path: '',
            name: 'PfSqlOverview',
            component: () => import('@/views/pfsql/PfSqlOverview.vue'),
            meta: {
              requiresAuth: true,
              authFn: canDescribePfSql,
              checkFn: ({ to }: { to: RouteLocationNormalized }) => {
                return (
                  !!to.query.org && !!to.query.instance && !!to.query.cluster && !!to.query.queryId
                )
              }
            }
          },
          {
            path: 'logs',
            name: 'PfSqlLogs',
            component: () => import('@/views/pfsql/PfSqlLogs.vue'),
            meta: {
              requiresAuth: true,
              authFn: canDescribePfSql,
              checkFn: ({ to }: { to: RouteLocationNormalized }) => {
                return (
                  !!to.query.org && !!to.query.instance && !!to.query.cluster && !!to.query.queryId
                )
              }
            }
          },
          {
            path: 'exceptions',
            name: 'PfSqlExceptions',
            component: () => import('@/views/pfsql/PfSqlExceptions.vue'),
            meta: {
              requiresAuth: true,
              authFn: canDescribePfSql,
              checkFn: ({ to }: { to: RouteLocationNormalized }) => {
                return (
                  !!to.query.org && !!to.query.instance && !!to.query.cluster && !!to.query.queryId
                )
              }
            }
          }
        ],
        meta: {
          requiresAuth: true,
          authFn: canDescribePfSql,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org && !!to.query.instance && !!to.query.cluster && !!to.query.queryId
          }
        }
      },
      {
        path: '/function/:name',
        component: () => import('@/views/function/FunctionPage.vue'),
        name: 'FunctionPage',
        props: true,
        redirect: { name: 'FunctionDashboard' },
        children: [
          {
            path: '',
            name: 'FunctionDashboard',
            component: () => import('@/views/function/FunctionDashboard.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              authFn: canDescribePulsarMetrics,
              checkFn: ({ to }: { to: RouteLocationNormalized }) => {
                return (
                  !!to.query.org &&
                  !!to.query.instance &&
                  !!to.query.cluster &&
                  !!to.query.tenant &&
                  !!to.query.namespace
                )
              },
              replaceFn: () => 'FunctionOverview'
            }
          },
          {
            path: '',
            name: 'FunctionOverview',
            component: () => import('@/views/function/FunctionOverview.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              authFn: canDescribeFunction,
              checkFn: ({ to }: { to: RouteLocationNormalized }) => {
                return (
                  !!to.query.org &&
                  !!to.query.instance &&
                  !!to.query.cluster &&
                  !!to.query.tenant &&
                  !!to.query.namespace
                )
              }
            }
          },
          {
            path: 'logs',
            name: 'FunctionLogs',
            props: true,
            component: () => import('@/views/function/FunctionLogs.vue'),
            meta: {
              requiresAuth: true,
              authFn: canDescribeFunction,
              checkFn: ({ to }: { to: RouteLocationNormalized }) => {
                return (
                  !!to.query.org &&
                  !!to.query.instance &&
                  !!to.query.cluster &&
                  !!to.query.tenant &&
                  !!to.query.namespace
                )
              }
            }
          },
          {
            path: 'exceptions',
            name: 'FunctionExceptions',
            props: true,
            component: () => import('@/views/function/FunctionExceptions.vue'),
            meta: {
              requiresAuth: true,
              authFn: canDescribeFunction,
              checkFn: ({ to }: { to: RouteLocationNormalized }) => {
                return (
                  !!to.query.org &&
                  !!to.query.instance &&
                  !!to.query.cluster &&
                  !!to.query.tenant &&
                  !!to.query.namespace
                )
              }
            }
          }
        ],
        meta: {
          requiresAuth: true,
          authFn: canDescribeFunction,
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return (
              !!to.query.org &&
              !!to.query.instance &&
              !!to.query.cluster &&
              !!to.query.tenant &&
              !!to.query.namespace
            )
          }
        }
      },
      {
        path: '/environment',
        name: 'CloudEnvironmentManagePage',
        redirect: { name: 'CloudEnvironmentsPage' },
        component: () => import('@/views/environment/CloudEnvironmentManagePage.vue'),
        children: [
          {
            path: 'environments',
            name: 'CloudEnvironmentsPage',
            component: () => import('@/views/environment/CloudEnvironmentsPage.vue'),
            meta: {
              requiresAuth: true,
              authFn: ({ to }: { to: RouteLocationNormalized }) => {
                return canDescribeCloudEnvironment({ to }) && canDescribeCloudConnection({ to })
              },
              checkFn: ({ to }: { to: RouteLocationNormalized }) => {
                return !!to.query.org
              },
              disableFn: async ({ ldClient }: { ldClient?: LDClient }) => {
                const flag = await ldClient?.variation(BYOC_BASE_ON_CLOUD_ENVIRONMENT_FLAG, false)
                return !flag
              }
            }
          },
          {
            path: 'connections',
            name: 'CloudConnectionsPage',
            component: () => import('@/views/environment/CloudConnectionsPage.vue'),
            meta: {
              requiresAuth: true,
              authFn: ({ to }: { to: RouteLocationNormalized }) => {
                return canDescribeCloudEnvironment({ to }) && canDescribeCloudConnection({ to })
              },
              checkFn: ({ to }: { to: RouteLocationNormalized }) => {
                return !!to.query.org
              },
              disableFn: async ({ ldClient }: { ldClient?: LDClient }) => {
                const flag = await ldClient?.variation(BYOC_BASE_ON_CLOUD_ENVIRONMENT_FLAG, false)
                return !flag
              }
            }
          }
        ],
        meta: {
          requiresAuth: true,
          authFn: ({ to }: { to: RouteLocationNormalized }) => {
            return canDescribeCloudEnvironment({ to }) && canDescribeCloudConnection({ to })
          },
          checkFn: ({ to }: { to: RouteLocationNormalized }) => {
            return !!to.query.org
          },
          disableFn: async ({ ldClient }: { ldClient?: LDClient }) => {
            const flag = await ldClient?.variation(BYOC_BASE_ON_CLOUD_ENVIRONMENT_FLAG, false)
            return !flag
          }
        }
      }
    ]
  },
  {
    path: '/deploying',
    name: 'DeployingPage',
    component: () => import('@/views/provision/DeployingPage.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      authFn: ({ to }: { to: RouteLocationNormalized }) => {
        return canCreateInstance({ to }) && canCreateCluster({ to })
      },
      checkFn: ({ to }: { to: RouteLocationNormalized }) => {
        return !!to.query.org
      }
    }
  },
  {
    path: '/products',
    name: 'ProductsPage',
    component: () => import('@/views/provision/ProductsPage.vue'),
    meta: {
      requiresAuth: true,
      authFn: canDescribeBilling,
      checkFn: ({ to }: { to: RouteLocationNormalized }) => {
        return !!to.query.org
      }
    }
  },
  {
    path: '/provision',
    name: 'ProvisionPage',
    component: () => import('@/views/provision/ProvisionPage.vue'),
    meta: {
      requiresAuth: true,
      authFn: ({ to }: { to: RouteLocationNormalized }) => {
        return to.query.cluster
          ? canUpdateInstance({ to }) && canUpdateCluster({ to })
          : canCreateInstance({ to }) && canCreateCluster({ to })
      },
      checkFn: ({ to }: { to: RouteLocationNormalized }) => {
        return !!to.query.org
      }
    }
  },
  {
    path: '/environment-provision',
    name: 'CloudEnvironmentProvisionPage',
    component: () => import('@/views/environment/CloudEnvironmentProvisionPage.vue'),
    meta: {
      requiresAuth: true,
      authFn: canCreateCloudEnvironment,
      checkFn: ({ to }: { to: RouteLocationNormalized }) => {
        return !!to.query.org
      },
      disableFn: async ({ ldClient }: { ldClient?: LDClient }) => {
        const flag = await ldClient?.variation(BYOC_BASE_ON_CLOUD_ENVIRONMENT_FLAG, false)
        return !flag
      }
    }
  }
].map(r => {
  // TODO authGuard is also called in beforeEach, is this necessary?
  return {
    ...r,
    beforeEnter: authGuard
  }
})

// These will be conditionally added the to the router for the dev server
// and e2e tests.
export const e2eRoutes = <Array<RouteRecordRaw>>[
  {
    path: '/e2e/tenantadmin',
    name: 'PulsarAdminTest',
    component: () => import('@/views/test/PulsarAdmin.vue'),
    beforeEnter: authGuard,
    meta: {
      requiresAuth: true
    }
  }
]

// this needs to added last and redirect to 404
const notFoundRoutes = <Array<RouteRecordRaw>>[
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
]

// https://router.vuejs.org/guide/advanced/meta.html
declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth?: boolean
    authFn?: ({
      to,
      debug
    }: {
      to?: RouteLocationNormalized
      debug?: any
    }) => Promise<boolean> | boolean
    checkFn?: ({
      to,
      debug
    }: {
      to?: RouteLocationNormalized
      debug?: any
    }) => Promise<boolean> | boolean
    disableFn?: ({
      to,
      ldClient,
      debug
    }: {
      to?: RouteLocationNormalized
      ldClient?: LDClient
      debug?: any
    }) => Promise<boolean> | boolean
    replaceFn?: ({
      to,
      ldClient,
      debug
    }: {
      to?: RouteLocationNormalized
      ldClient?: LDClient
      debug?: any
    }) => Promise<RouteLocationNormalized | string> | RouteLocationNormalized | string
  }
}

export default openRoutes.concat(authRoutes).concat(notFoundRoutes)
